import config from 'config'
import {
  getMediaGalleryLarge,
  configurableChildrenImagesLarge,
  attributeImagesLarge
} from './'
import uniqBy from 'lodash-es/uniqBy'
import Product from '@vue-storefront/core/modules/catalog/types/Product';

export default function getProductLargeGallery (product: Product) {
  if (product.type_id === 'configurable' && product.hasOwnProperty('configurable_children')) {
    if (!config.products.galleryLarge.mergeConfigurableChildren && product.is_configured) {
      return attributeImagesLarge(product)
    }
  }

  const productLargeGallery = uniqBy(configurableChildrenImagesLarge(product).concat(getMediaGalleryLarge(product)), 'src')
    .filter(f => f.src && f.src !== config.images.productPlaceholder)

  return productLargeGallery
}
