export function objEmpty (obj: Record<string, any>): boolean {
  for (let prop in obj) {
    return false;
  }
  return true;
}

export type traverser = (
  source: Record<string, any> | any[],
  extractChildren: CallableFunction,
  processFunction: CallableFunction
) => void

export function traverse (
  source: Record<string, any> | any[],
  extractChildren: CallableFunction,
  processFunction: CallableFunction
): void {
  processFunction(source);
  for (let child of extractChildren(source)) {
    traverse(child, extractChildren, processFunction);
  }
}
export function traverseDFS (
  source: Record<string, any> | any[],
  extractChildren: CallableFunction,
  processFunction: CallableFunction
): void {
  processFunction(source);
  let stack = [source];

  while (stack.length) {
    let current = stack.pop();
    processFunction(current);
    stack = stack.concat(extractChildren(current));
  }
}
export function traverseBFS (
  source: Record<string, any> | any[],
  extractChildren: CallableFunction,
  processFunction: CallableFunction
): void {
  processFunction(source);
  let queue = [source];

  while (queue.length) {
    let current = queue.shift();
    processFunction(current);
    queue = queue.concat(extractChildren(current));
  }
}
