/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { DeepestCategoryStrategy } from './deepest_categories_strategy/DeepestCategoryStrategy'
import { AlgoStrategy } from './deepest_categories_strategy/AlgoStrategy'

export interface CategoryResolverParameters{
  deepestCategoryStrategy?: DeepestCategoryStrategy|null,
  cropCategoriesBefore?: number,
  skipCategoriesNotInIndex?: boolean
}

export class CategoryResolverConfig {
  cropCategoriesBefore: number
  skipCategoriesNotInIndex: boolean
  deepestCategoryStrategy: DeepestCategoryStrategy
  constructor (params?: CategoryResolverParameters) {
    this.cropCategoriesBefore = (
      params && typeof params.cropCategoriesBefore !== 'undefined'
    ) ? params.cropCategoriesBefore : 0

    this.skipCategoriesNotInIndex = (
      params && typeof params.skipCategoriesNotInIndex !== 'undefined'
    ) ? params.skipCategoriesNotInIndex : true;
    this.deepestCategoryStrategy = (params && params.deepestCategoryStrategy) || new AlgoStrategy()
  }
}
