import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { DataResolver } from './types/DataResolver'
import { processURLAddress } from '@vue-storefront/core/helpers'
import config from 'config'
import rootStore from '@vue-storefront/core/store'
import { getSearchQuery, getLocale } from '@vue-storefront/core/helpers/akeneo'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import Task from '@vue-storefront/core/lib/sync/types/Task'

const getTaskExecute = async (url): Promise<Task> =>
  TaskQueue.execute({
    url: processURLAddress(url),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }
  })

const postProductTaskExecute = async (url, product): Promise<Task> =>
  TaskQueue.execute({
    url: processURLAddress(url),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(product)
    }
  })
const postAssetTaskExecute = async (url, formData): Promise<Task> =>
  TaskQueue.execute({
    url: processURLAddress(url),
    payload: {
      method: 'POST',
      mode: 'cors',
      body: formData
    }
  })

const getProducts = async (limit?: number, filters?: Record<any, any>[], page?: number): Promise<DataResolver.AkeneoProductsListResponse> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'products_endpoint')}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)
  url = `${url}&locales=${getLocale()}&with_count=true`
  if (filters && filters.length) {
    url = `${url}&search=${getSearchQuery(filters)}`
  }
  if (limit) {
    url = `${url}&limit=${limit}`
  }
  if (page) {
    url = `${url}&page=${page}`
  }

  const { result } = await getTaskExecute(url)
  return result
}

const getCategories = async (limit?: number, page?: number): Promise<DataResolver.AkeneoCategoryListResponse> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'categories_endpoint')}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)
  if (limit) {
    url = `${url}&limit=${limit}`
  }
  if (page) {
    url = `${url}&page=${page}`
  }
  url = `${url}&search={"parent":[{"operator":"=","value": "${config.akeneo.category_ids.parent}"}]}` // get only clothes child

  const { result } = await getTaskExecute(url)
  return result
}

const getFamily = async (code: string): Promise<DataResolver.AkeneoFamilyResponse> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'families_endpoint').replace('{{code}}', code)}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)
  url = `${url}&limit=30` // TODO: get  limit as variable from config

  const { result } = await getTaskExecute(url)
  return result
}

const getAttributes = async (attrList: string[]): Promise<DataResolver.AkeneoAttributesListResponse> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = getApiEndpointUrl(config.akeneo, 'atributes_endpoint')
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)
  url = `${url}&limit=100` // TODO: get  limit as variable from config
  if (attrList && attrList.length) {
    url = `${url}&search={"code":[{"operator":"IN","value":${JSON.stringify(attrList)}}]}`
  }

  const { result } = await getTaskExecute(url)
  return result
}

const getAttributeOptions = async (code: string, limit?: number, page?: number): Promise<DataResolver.AkeneoAttributeOptionListResponse> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'attribute_options_endpoint').replace('{{attribute_code}}', code)}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)

  if (limit) {
    url = `${url}&limit=${limit}`
  }
  if (page) {
    url = `${url}&page=${page}`
  }
  const { result } = await getTaskExecute(url)
  return result
}
const createSeveralProducts = async (product: any): Promise<Task> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'products_endpoint')}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)

  const response = await postProductTaskExecute(url, product)
  return response
}

const setAssetForProduct = async (product: any, file: any): Promise<Task> => {
  const currentToken = rootStore.getters['user/getUserToken']
  let url = `${getApiEndpointUrl(config.akeneo, 'asset_endpoint')}`
  url = url.replace('{{token}}', (currentToken == null) ? '' : currentToken)
  const formData = new FormData();
  formData.append('product', JSON.stringify(product))
  formData.append('file', file, new Date().valueOf() + file.name)

  const response = await postAssetTaskExecute(url, formData)
  return response
}

export const AkeneoService: DataResolver.AkeneoService = {
  getFamily,
  getAttributes,
  getProducts,
  setAssetForProduct,
  getCategories,
  getAttributeOptions,
  createSeveralProducts
}
