/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  GATracker,
  Product,
  ImpressionArgs,
  DetailArgs,
  ClickArgs,
  PromotionViewArgs,
  PromotionClickArgs,
  AddToCartArgs,
  RemoveFromCartArgs,
  PurchaseArgs,
  CheckoutArgs,
  PageViewArgs,
  StateCheckout,
  AddToWishlistArgs,
  RemoveFromWishlistArgs,
  Optional,
  ProductProcessing,
  UserFormArgs
} from './interfaces';
import {
  getDL,
  getProductCategories,
  getProductIds,
  getProductPrices,
  patchObject,
  getGaConfig,
  getCurrency,
  adapterUserAddress,
  getLanguage
} from './common';

export class GA implements GATracker {
  cleanup () {
    let dl = getDL()
    dl.push({
      v: null,
      items: null,
      eventCategory: null,
      eventAction: null,
      ecommerce: null
    })
  }
  pageView ({
    url = undefined,
    referrer = undefined,
    title = undefined,
    type = undefined,
    viewName = undefined,
    user = undefined,
    otherGlobal = undefined
  }: PageViewArgs): void {
    let dl = getDL()
    let res = { event: 'pageview' }

    res['v'] = 4
    res['page-location'] = url
    res['page-title'] = title
    res['content-type'] = type
    res['content-view-name'] = viewName
    res['language'] = getLanguage()
    if (user) {
      let address = adapterUserAddress(user)
      res['user-id'] = user.id
      res['user-name'] = user.firstname
      res['user-surname'] = user.lastname
      res['user-email'] = user.email
      res['user-city'] = address.city
      res['user-country'] = address.country
    }

    patchObject(res, otherGlobal);
    this.cleanup();
    dl.push(res);
  };

  userForm ({
    name,
    user,
    consents = undefined,
    otherGlobal = undefined
  }: UserFormArgs): void {
    let dl = getDL()
    let res = {
      event: 'userform',
      eventAction: 'Form Sent',
      eventCategory: name
    }

    res['extension_attributes'] = consents
    res['language'] = getLanguage()
    res['v'] = 4
    if (user) {
      let address = adapterUserAddress(user)
      res['user-id'] = user.id
      res['user-name'] = user.firstname
      res['user-surname'] = user.lastname
      res['user-email'] = user.email

      res['user-city'] = address.city
      res['user-country'] = address.country
    }
    patchObject(res, otherGlobal);
    this.cleanup();
    dl.push(res);
  };

  attachCategories (category: string, product: object): object {
    if (!category) return product;
    let categories = category.split('/')
    for (let i = 0; (i < categories.length) && (i < 5); i++) {
      product['item_category' + (i ? `${i + 1}` : '')] = categories[i]
    }
    return product
  }

  paymentType (checkout?: StateCheckout): string | undefined {
    if (!checkout) return undefined;
    let paymentCode = checkout.paymentDetails && checkout.paymentDetails.paymentMethod
    let payment
    if (paymentCode) {
      let mappedPayment = {};
      (checkout.paymentMethods ? checkout.paymentMethods : []).forEach((x) => {
        if (x.code) {
          mappedPayment[x.code] = x.title
        }
      })
      payment = mappedPayment[paymentCode] || paymentCode
    }
    return payment
  }

  shippingTier (checkout?: StateCheckout): string | undefined {
    if (!checkout) return undefined;
    let methodCode = checkout.shippingDetails && checkout.shippingDetails.shippingMethod
    let carrierCode = checkout.shippingDetails && checkout.shippingDetails.shippingCarrier
    let method
    let carrier

    if (methodCode) {
      let mappedMethod = {};
      (checkout.shippingMethods ? checkout.shippingMethods : []).forEach((x) => {
        if (x.method_code) {
          mappedMethod[x.method_code] = x.method_title
        }
      })
      method = mappedMethod[methodCode] || methodCode
    }
    if (carrierCode) {
      let mappedCarrier = {};
      (checkout.shippingMethods ? checkout.shippingMethods : []).forEach((x) => {
        if (x.carrier_code) {
          mappedCarrier[x.carrier_code] = x.carrier_title
        }
      })
      carrier = mappedCarrier[carrierCode] || carrierCode
    }
    if (!(method || carrier)) return undefined;
    return [method, carrier].filter(Boolean).join(' - ')
  }

  mapProduct (product: Product, how: ProductProcessing = ProductProcessing.Regular): object {
    let { id, dbId, parentDbId, childSku, parentSku } = getProductIds(product);
    let { tax, price } = getProductPrices(product);
    let trackEdrone = getGaConfig('trackEdrone', false)
    let { category, product_category_ids, product_category_names } = getProductCategories(product, trackEdrone);
    let res: object = {
      item_id: id,
      item_name: product.name,
      currency: product.currency || getCurrency(),
      discount: 0, // TODO
      item_brand: product.brand,
      item_variant: undefined, // TODO
      tax: tax,
      price: price,

      // non parsable by google, usable in other tags
      dbId: dbId,
      parentDbId: parentDbId,
      childSku: childSku,
      parentSku: parentSku
    }

    if (product.affiliation) { res['affiliation'] = product.affiliation }
    if (how === ProductProcessing.List) {
      if (product['list']) {
        res['item_list_id'] = product['list']
        res['item_list_name'] = product['list']
      }
      if (product['position']) { res['index'] = product['position'] }
    }
    if (how === ProductProcessing.InCart) {
      if (product['qty']) { res['quantity'] = product['qty'] }
      if (product['coupon']) { res['coupon'] = product['coupon'] }
    }
    this.attachCategories(category, res)
    if (trackEdrone) {
      res['product_category_ids'] = product_category_ids;
      res['product_category_names'] = product_category_names;
    }
    if (product.ga_fields) {
      res = Object.assign(res, product.ga_fields)
    }
    return res
  }
  extractPromo (
    {
      banner,
      creative_name,
      creative_slot,
      promotion_id,
      promotion_name,
      otherGlobal,
      otherItem
    }: PromotionViewArgs | PromotionClickArgs,
    eventName: string
  ): object {
    const id = promotion_id || ('' + banner.id);
    const name = promotion_name || creative_name || banner.text1;
    const pos = creative_slot || (banner.order || '1');
    const creative = creative_name || banner.urlDesktop || banner.urlMobile;
    const item = {
      item_id: banner.alt || id,
      item_name: name
    };
    const ecommerce = {
      v: 4,
      event: eventName,
      creative_name: creative,
      creative_slot: pos,
      promotion_id: id,
      promotion_name: name,
      promo_start_date: banner.from,
      promo_end_date: banner.to,
      items: [item]
    }
    patchObject(ecommerce, otherGlobal);
    patchObject(item, otherItem);

    return ecommerce;
  }
  impressions ({
    impressions,
    otherGlobal = undefined
  }: ImpressionArgs): void {
    let dl = getDL()
    let ecommerceItems = impressions.map((x) => this.mapProduct(x, ProductProcessing.List))
    let ecommerce = {}

    ecommerce = {
      v: 4,
      event: 'view_item_list',
      eventAction: 'Impressions',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: ecommerceItems
    }
    if (typeof otherGlobal !== 'undefined') {
      patchObject(ecommerce, otherGlobal)
    }
    this.cleanup()
    dl.push(ecommerce)
  }

  click ({
    item,
    otherGlobal = undefined
  }: ClickArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(item, ProductProcessing.List);
    let ecommerce = {
      v: 4,
      event: 'select_item',
      eventAction: 'Product Click',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)
    this.cleanup();
    dl.push(ecommerce)
  };
  promo (args: PromotionViewArgs): void {
    let dl = getDL()

    this.cleanup();
    dl.push(this.extractPromo(args, 'view_promotion'))
  }

  promoClick (args: PromotionClickArgs): void {
    let dl = getDL()
    this.cleanup();
    dl.push(this.extractPromo(args, 'select_promotion'))
  }

  detail ({
    product,
    otherGlobal = undefined
  }: DetailArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    let ecommerce = {
      v: 4,
      event: 'view_item',
      eventAction: 'Product Detail',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)
    this.cleanup();
    dl.push(ecommerce)
  };

  addToCart ({
    product,
    otherGlobal = undefined
  }: AddToCartArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(product, ProductProcessing.InCart);
    let ecommerce = {
      v: 4,
      event: 'add_to_cart',
      eventAction: 'Add to Cart',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)

    this.cleanup();
    dl.push(ecommerce)
  };

  removeFromCart ({
    product,
    otherGlobal = undefined
  }: RemoveFromCartArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(product, ProductProcessing.InCart);
    let ecommerce = {
      v: 4,
      event: 'remove_from_cart',
      eventAction: 'Remove from Cart',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)

    this.cleanup();
    dl.push(ecommerce)
  };
  wishlist ({
    product,
    list,
    otherItem,
    otherGlobal
  }: AddToWishlistArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    let ecommerce = {
      v: 4,
      event: 'add_to_wishlist',
      eventAction: 'Add To wishlist',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      wishlist: list,
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)
    patchObject(ecommerceItem, otherItem)
    this.cleanup();
    dl.push(ecommerce)
  }
  wishlistRemove ({
    product,
    list,
    otherItem,
    otherGlobal
  }: RemoveFromWishlistArgs): void {
    let dl = getDL();
    let ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    let ecommerce = {
      v: 4,
      event: 'remove_from_wishlist',
      eventAction: 'Remove From wishlist',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      wishlist: list,
      items: [ecommerceItem]
    };
    patchObject(ecommerce, otherGlobal)
    patchObject(ecommerceItem, otherItem)
    this.cleanup();
    dl.push(ecommerce)
  }

  push (obj: Optional): void {
    let dl = getDL()
    obj['v'] = '4';
    obj.event = obj.event || 'custom_event'
    this.cleanup();
    dl.push(obj)
  }

  checkout ({
    products,
    step,
    stateCheckout = undefined,
    otherGlobal = undefined,
    otherCheckout = undefined
  }: CheckoutArgs): void {
    let dl = getDL();
    let ecommerceItems = products.map((x) => this.mapProduct(x, ProductProcessing.InCart));
    let ecommerce = {
      v: 4,
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: ecommerceItems
    };
    let ecommerceExtended = {
      v: 4,
      event: 'checkout_progress',
      checkout_step: step,
      eventCategory: 'Ecommerce GA4 (Extended)',
      eventAction: 'Checkout Progress',
      currency: getCurrency(),
      items: ecommerceItems
    };
    if (step === 1) {
      ecommerce['event'] = 'view_cart'
    } else if (step === 2) {
      ecommerce['event'] = 'begin_checkout'
    } else if (step === 4) {
      // shipping
      ecommerce['event'] = 'add_shipping_info'
      ecommerce['shipping_tier'] = this.shippingTier(stateCheckout)
    } else if (step === 5) {
      // payment
      ecommerce['event'] = 'add_payment_info'
      ecommerce['payment_type'] = this.paymentType(stateCheckout)
    }
    patchObject(ecommerce, otherGlobal)
    patchObject(ecommerceExtended, otherCheckout)
    if (ecommerce['event']) {
      this.cleanup();
      dl.push(ecommerce)
    }
    this.cleanup();
    dl.push(ecommerceExtended)
  }

  purchase ({
    cartTotals,
    orderDetails,
    products,
    otherGlobal = undefined,
    otherPurchase = undefined
  }: PurchaseArgs): void {
    let dl = getDL();
    let ecommerceItems = products.map((x) => this.mapProduct(x, ProductProcessing.InCart));
    let dbOrderId = (getGaConfig('dbOrderId', false) as boolean);
    let orderId = dbOrderId && orderDetails.order_id ? orderDetails.order_id : orderDetails.order_increment_id;
    let backendEcommerce = (getGaConfig('backendEcommerce', true) as boolean);
    let trackTax = (getGaConfig('trackTax', false) as boolean);
    let trackShipping = (getGaConfig('trackShipping', true) as boolean);
    let ecommerce = {
      v: 4,
      event: 'purchase',
      eventAction: 'Purchase',
      eventCategory: 'Ecommerce GA4',
      transaction_id: orderId,
      currency: getCurrency(),
      items: ecommerceItems
    };
    let ecommerceExtended = {
      v: 4,
      event: 'purchase_for_tags',
      eventCategory: 'Purchase for Tags',
      eventAction: 'Purchase',
      transaction_id: orderId,
      currency: getCurrency(),
      items: ecommerceItems
    };
    let totals: object = {};

    cartTotals.forEach((item) => { totals[item.code] = item.value })

    if (trackTax) {
      ecommerce['tax'] = totals['tax']
      ecommerceExtended['tax'] = totals['tax']
    }
    ecommerce['value'] = totals['grand_total']
    ecommerceExtended['value'] = totals['grand_total']

    if (trackShipping) {
      let shipping = totals['shipping'] || 0
      shipping += totals['cash_on_delivery_fee'] || 0
      
      ecommerce['shipping'] = shipping
      ecommerceExtended['shipping'] = shipping
    }

    patchObject(ecommerce, otherGlobal);
    patchObject(ecommerceExtended, otherGlobal);

    patchObject(ecommerce, otherPurchase);
    patchObject(ecommerceExtended, otherPurchase);

    if (!backendEcommerce) {
      this.cleanup();
      dl.push(ecommerce);
    }
    this.cleanup();
    dl.push(ecommerceExtended);
  }
}
