import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { localizedRoute, currentStoreView } from '@vue-storefront/core/lib/multistore'

// Duplicate of breadCrumbRoutes, to repalce it soon.
/** Parse category path for product/category  */
export function parseCategoryPath (categoryPath) {
  let routesArray = []
  for (let category of categoryPath) {
    if (category.url_path === undefined || category.url_path === null) continue;
    routesArray.push({
      id: category.id,
      name: category.name,
      route_link: localizedRoute('/' + category.url_path)
    })
  }

  return routesArray
}
