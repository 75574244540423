import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import StockState from '../../types/StockState'
import getters from './getters'

export const stockModule: Module<StockState, RootState> = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state: {
    cache: {},
    isStockInfoLoading: false,
    maxQuantity: 0
  }
}
