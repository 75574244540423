
export interface CategoryResolver{
  setCategories (categories: any[], update?: boolean): void,
  getDeepestCategories (
    categoriesList: any[],
    pretty?: boolean,
    categories?: any[],
    update?: boolean
  ): string[],
  getNamedPath (path: string, startingFrom?: number): string,
  getCategoriesIndex(): { [key: number]: { path: string, name: string } }
}

export interface Optional {[key: string]: string}
export interface Consents {[key: string]: boolean}
export interface Config {
  googleTagManager? : {
    id?: string,
    debug?: boolean,
    version?: number, // GA version 3/4 default 3, (in 2023 and after default 4), should be used by external code
    idParam?: string, // field used as product id sku|parentSku|id|parentId
    backendEcommerce?: boolean, // is purchase tracked on backend
    session_id_cookie?: string,
    priceParam?: string, // parameter to use as price original|final
    trackTax?: boolean, // should tax be tracked
    taxInPrice?: boolean, // is tax included in price (no use now, vsf lacks price fields)
    mainCategory?: number, // id of the category from which to start categories ex. Sklep, Towary etc.
    trackEdrone?: boolean, // should we pass edrone-formatted categories
    dbOrderId?: boolean, // should we use entity id instead of increment id for order
    trackShipping?: boolean, // should we track shipping prices
    brandField?: string // should contain attribute used as brand, should be used externally
  }
}

export interface Product { // fields that are not present in
  id: number,
  name?: string,
  parentId?: number,
  sku: string,
  parentSku?: string,
  category: {
    category_id: number
  }[],
  category_ids?: number[],
  image?: string,
  currency?: string, // GA
  original_price?: number,
  original_price_incl_tax?: number,
  final_price?: number,
  final_price_incl_tax?: number,
  original_price_tax?: number,
  final_price_tax?: number,
  brand?: string, // GA (get label for)
  affiliation?: string, // GA
  location_id?: string, // GA
  ga_fields?: Optional
}
export interface ProductInList extends Product{
  position?: number, // GA
  list?: string, // GA
  list_id?: string // GA
}

export interface ProductInCart extends Product{
  qty?: number, // GA
  coupon?: string // GA
}

export interface Banner {
  alt?: string,
  from?: string,
  hex?: string,
  id: number,
  link?: string,
  order?: string,
  text1?: string,
  text1color?: string,
  text2?: string,
  text2color?: string,
  to?: string,
  urlDesktop?: string,
  urlMobile?: string
}

export interface User {
  id: number,
  lastname: string,
  firstname: string,
  addresses?: {
    city?: string,
    country_id?: string,
    postcode?: string,
    region?: {
      region?: string,
      region_code?: string,
      region_id?: number
    },
    street?: string,
    telephone?: string
  }[],
  custom_attributes: {[key: string]: any},
  email?: string,
  extension_attributes: {is_subscribed: boolean}
}
export interface OrderDetails {
  shipping?: {
    firstName?: string,
    lastName?: string,
    country?: string,
    streetAddress?: string,
    apartmentNumber?: string,
    city?: string,
    state?: string,
    region_id?: number,
    zipCode?: string,
    phoneNumber?: string,
    shippingMethod?: string,
    shippingCarrier?: string,
    pickupId?: string
  },
  payment: {
    firstName?: string,
    lastName?: string,
    company?: string,
    country?: string,
    streetAddress?: string,
    apartmentNumber?: number,
    city?: string,
    state?: string,
    region_id?: string,
    zipCode?: string,
    phoneNumber?: string,
    taxId?: string,
    paymentMethod?: string,
    paymentMethodAdditional?: object,
    emailAddress?: string,
    type?: string,
    regon?: string
  },
  personal: {
    firstName?: string,
    lastName?: string,
    emailAddress?: string,
    country?: string,
    streetAddress?: string,
    apartmentNumber?: string,
    city?: string,
    state?: string,
    region_id?: number,
    zipCode?: string,
    phoneNumber?: string,
    password?: string,
    createAccount?: boolean
  },
  order_id?: string,
  order_increment_id?: string
}

export interface StateCheckout { // .checkout field
  paymentDetails?: { paymentMethod: string },
  paymentMethods?: { code: string, is_server_method?: boolean, title?: string }[],
  shippingDetails?: { shippingCarrier?: string, shippingMethod?: string },
  shippingMethods?: {
    carrier_code?: string,
    carrier_title?: string,
    method_code?: string,
    method_title?: string,
    price_excl_tax?: number,
    price_incl_tax?: number
  }[]
}
export interface CartTotal { // named cardTotals on checkout component
  code: string,
  title?: string,
  value?: number
}

// ga common args
export interface PageViewArgs {
  url?: string, // content-name
  referrer?: string,
  title?: string,
  type?: string,
  viewName?: string,
  user?: User,
  otherGlobal?: Optional
}

export interface UserFormArgs { // changes - should be passed after signUp\signIn when user object was obtained
  name: string,
  user: User,
  consents?: Consents, // ex. extension_attributes consents object on kultowy
  otherGlobal?: Optional
}

// ga ecommerce args
export interface ImpressionArgs {
  impressions: ProductInList[], // Product
  otherGlobal?: Optional
}

export interface ClickArgs {
  item: ProductInList,
  otherGlobal?: Optional
}
export interface DetailArgs {
  product: Product,
  otherGlobal?: Optional
}

export interface AddToCartArgs {
  product: ProductInCart,
  otherGlobal?: Optional
}

export interface RemoveFromCartArgs {
  product: ProductInCart,
  otherGlobal?: Optional
}

export interface AddToWishlistArgs {
  product: Product,
  list?: string,
  otherItem?: Optional,
  otherGlobal?: Optional
}

export interface RemoveFromWishlistArgs {
  product: Product,
  list?: string,
  otherItem?: Optional,
  otherGlobal?: Optional
}

export interface CheckoutArgs {
  products: ProductInCart[],
  step: number,
  stateCheckout?: StateCheckout,
  otherGlobal?: Optional,
  otherCheckout?: Optional
}

export interface PurchaseArgs {
  cartTotals: CartTotal[],
  orderDetails: OrderDetails,
  products: ProductInCart[],
  otherGlobal?: Optional,
  otherPurchase?: Optional
}

export interface PromotionViewArgs {
  banner: Banner,
  creative_name?: string,
  creative_slot?: string,
  promotion_id?: string,
  promotion_name?: string,
  otherGlobal?: Optional,
  otherItem?: Optional
}
export interface PromotionClickArgs {
  banner: Banner,
  creative_name?: string,
  creative_slot?: string,
  promotion_id?: string,
  promotion_name?: string,
  otherGlobal?: Optional,
  otherItem?: Optional
}

export enum ProductProcessing {
  Regular,
  List,
  InCart,
}

export interface GATracker{
  // common
  pageView(args: PageViewArgs): void,
  userForm(args: UserFormArgs): void,
  // ga version specific
  impressions(args: ImpressionArgs): void,
  click(args: ClickArgs): void,
  promo(args: PromotionViewArgs): void,
  promoClick(args: PromotionClickArgs): void,
  wishlist(args: AddToWishlistArgs): void,
  wishlistRemove(args: RemoveFromWishlistArgs): void,
  detail(args: DetailArgs): void,
  addToCart(obj: AddToCartArgs): void,
  removeFromCart(obj: RemoveFromCartArgs): void,
  checkout(obj: CheckoutArgs): void,
  purchase(obj: PurchaseArgs): void,
  push(obj: Optional): void
}
