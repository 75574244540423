import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { categoryResolver } from 'theme/helpers/categoryResolverFunction'

import { GATracker } from 'src/modules/vsf-beecommerce-ga-tracking/interfaces'
import { currency } from 'src/modules/vsf-beecommerce-ga-tracking/common'
import { CategoryResolver } from 'theme/helpers/category_resolver/CategoryResolver'
import { config as trackingConfig } from 'src/modules/vsf-beecommerce-ga-tracking/tracking'
import groupBy from 'lodash-es/groupBy'
import config from 'config'

export const setGTMconfig = function (store) {
  if (Vue.prototype.$googleTagManager) {
    const resolver = CategoryResolver.get()
    const categoriesMap = groupBy(store.getters['category-next/getMenuCategories'], 'parent_id')
    const categoryChilds = categoriesMap[config.server.categoryIdsMap.rootCategoryId]
    const mainCategory = categoryChilds && categoryChilds.find(category => category.id === config.server.categoryIdsMap.mainCategoryId)
    const mainMarketplaceCategory = categoryChilds && categoryChilds.find(category => category.id === config.server.categoryIdsMap.marketplaceCategoryId)
    const categories = [mainCategory, mainMarketplaceCategory].filter(category => category)
    const currency = currentStoreView().i18n.currencyCode
    const language = currentStoreView().i18n.defaultLanguage

    trackingConfig({ resolver, categories, currency, language })
  };
}
const getResolverData = (item, store, config): any => {
  if (!store || !item || !config) return ''
  let { deepestCategory, categoriesIdsFromResolver } = categoryResolver(item.category, store, config.server.categoryIdsMap.mainCategoryId)
  categoriesIdsFromResolver = categoriesIdsFromResolver.length ? categoriesIdsFromResolver[0] : ''
  deepestCategory = deepestCategory.length ? deepestCategory[0] : ''
  return { deepestCategory, categoriesIdsFromResolver }
}

const getDesigner = (item, store): string => {
  if (!store || !item) return ''
  const productDesigner = item.type_id !== 'simple' ? 'designer' : 'marketplace_designer'
  const attributes = store.getters['attribute/attributeListByCode']

  if (attributes[productDesigner]) {
    let designer = ''
    attributes[productDesigner].options.forEach(option => {
      if (option.value === String(item[productDesigner])) {
        designer = option.label
        return designer;
      }
    })
    return designer
  } else if (item[productDesigner]) {
    return item[productDesigner]
  }
  return ''
}

const getPriceForGA = (item): number => {
  if (item.sirental_price && item.sirental_price.length) {
    let sirentalPrices = JSON.parse(JSON.stringify(item.sirental_price))
    if (sirentalPrices && sirentalPrices.length) {
      const minSirentalPrice = sirentalPrices
        .filter(price => price.price)
        .reduce((prev, curr) => {
          if (curr.period) { curr.period = curr.period.replace('d', '') }
          return (prev.price < curr.price)
            ? prev
            : curr
        }, 0)
      if (minSirentalPrice.price) {
        return Number(minSirentalPrice.price)
      }
    }
  } else {
    return Number(item.final_price_incl_tax)
  }
  return 0
}

const priceToLocaleString = (price): number => {
  if (!price) return 0
  let localizedPrice = Math.abs(price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')
  return Number(localizedPrice)
}

export const getNextProduct = (
  {
    item,
    list = undefined,
    list_id = undefined,
    position = undefined
  },
  store?
) => {
  let newItem = JSON.parse(JSON.stringify(item))
  newItem.ga_fields = {}
  const designer = getDesigner(item, store)
  if (designer) {
    newItem['brand'] = designer
  }
  const prettyPrice = priceToLocaleString(getPriceForGA(item))
  if (prettyPrice) {
    newItem.ga_fields['price'] = prettyPrice
  }
  return {
    ...newItem,
    list,
    list_id,
    position
  }
}

export const getCartItems = (cartItems, fullPath, store, config) => {
  return cartItems.map((item, index) =>
    getNextProduct({
      item,
      list: fullPath === '/checkout' ? 'Checkout' : 'Microcart',
      position: index + 1
    }, store)
  )
}

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>) {
  const GTM: GATracker = Vue.prototype.$googleTagManager
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    // TODO: try to change on native trackView with custom params
    GTM.trackView = function (screenName, path) {}

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode
    const getProduct = (item) => {
      const { id, image, name, sku, parentSku, category, qty: quantity } = item
      const designer = getDesigner(item, store)
      const prettyPrice = priceToLocaleString(getPriceForGA(item))
      const { deepestCategory, categoriesIdsFromResolver } = getResolverData(item, store, config)

      let product = {
        name: name,
        childSku: sku,
        fbId: id,
        id: parentSku,
        price: prettyPrice,
        brand: designer,
        category: deepestCategory,
        product_category_ids: categoriesIdsFromResolver,
        product_images: config.api.imagesUrl + '/1000/1000/resize/product' + image
      }
      if (quantity) {
        product['quantity'] = quantity
      }

      return product
    }

    const checkoutObj = (step, products, user?) => {
      let cartProducts = []
      let productIds = []
      let total = 0
      products.map(product => {
        productIds.push(product.id)
        total += product.price
      })
      for (let product of products) {
        cartProducts.push(getProduct(product))
      }
      return {
        event: 'checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'checkout',
        ecomm_prodid: productIds,
        ecomm_pagetype: 'cart',
        ecomm_totalvalue: total,
        ecommerce: {
          checkout: {
            actionField: {
              step: step
            },
            products: cartProducts
          }
        }
      }
    }

    store.subscribe(({ type, payload }, state) => {
      const target = (window as any);
      let dataLayer = (target.dataLayer = target.dataLayer || [])
      // set type of page
      const setCurrentPage = () => {
        let isProductPage = !!payload.to.params.parentSku
        let isCheckoutPage = payload.to.name.includes('checkout')
        let isCategoryPage = payload.to.name && payload.to.name.includes('urldispatcher') && !isProductPage
        let isHomePage = payload.to.name.includes('home')
        let isAccountPage = payload.to.name.includes('my-')
        let isPostIndexPage = payload.to.name.includes('posts-index')
        let isPostShowPage = payload.to.name.includes('posts-show')
        let isStaticPage = payload.to.name.includes('cms-page')
        let isPageNotFound = payload.to.name.includes('page-not-found')
        if (isPostShowPage) {
          return 'posts-show'
        } else if (isPostIndexPage) {
          return 'posts-index'
        } else if (isHomePage) {
          return 'homepage'
        } else if (isProductPage) {
          return 'product'
        } else if (isCheckoutPage) {
          return 'checkout'
        } else if (isStaticPage) {
          return 'static-page'
        } else if (isPageNotFound) {
          return 'page-not-found'
        } else if (isAccountPage) {
          return 'my-account'
        } else if (isCategoryPage) {
          return 'category'
        } else {
          return 'unknown'
        }
      }
      const getPageTitle = () => {
        let currentCategory = store.getters['category-next/getCurrentCategory']
        let pageTitle = ''
        switch (setCurrentPage()) {
          case 'homepage':
            pageTitle = 'Strona główna'
            break
          case 'posts-show':
            pageTitle = state.wordpress.currentPost ? state.wordpress.currentPost.title : 'Egarderobe | Wypożyczalnia luksusowych ubrań dla modnych kobiet od znanych projektantów. Szeroki wybór markowej odzieży. Szybko i sprawnie przez Internet w E-Garderobe!'
            break
          case 'product':
            pageTitle = state.product.current.meta_title || state.product.current.name
            break
          case 'checkout':
            pageTitle = 'Płatność'
            break
          case 'static-page':
            pageTitle = state.cmsPage.current ? state.cmsPage.current.meta_title || state.cmsPage.current.title : ''
            break
          case 'page-not-found':
            pageTitle = '404 Nie ma takiej strony'
            break
          case 'category':
            pageTitle = Object.keys(currentCategory).length
              ? currentCategory.meta_title || currentCategory.name
              : ''
            break
          default:
            pageTitle = 'Egarderobe | Wypożyczalnia luksusowych ubrań dla modnych kobiet od znanych projektantów. Szeroki wybór markowej odzieży. Szybko i sprawnie przez Internet w E-Garderobe!'
            break
        }
        if (pageTitle.length < 45) return pageTitle.concat(' - Egarderobe.pl')
        if (pageTitle.length > 60) return pageTitle.slice(0, 60)
        return pageTitle
      }
      // Changing route
      if (type === 'ui/routeChanged' || (type === 'route/ROUTE_CHANGED' && payload.to.name.includes('checkout') && payload.to.hash)) {
        if (!currency) setGTMconfig(store)
        let userAddress = state.user.current && state.user.current.addresses.length
          ? state.user.current.addresses[0]
          : ''
        dataLayer.push({
          event: 'route-changed',
          'content-title': getPageTitle(),
          'content-type': setCurrentPage(),
          'content-name': payload.to.path,
          'content-view-name': payload.to.name,
          'visitor-login-state': state.user.current ? 'logged-in' : 'logged-out',
          'user-email': state.user.current && state.user.current.email,
          'user-name': state.user.current ? state.user.current.firstname : '',
          'user-surname': state.user.current ? state.user.current.lastname : '',
          'user-city': userAddress && userAddress.city,
          'user-country': userAddress && userAddress.country_id,
          language: state.storeView.storeCode
        })
        dataLayer.push({
          event: 'content-view',
          'content-name': payload.to.path,
          'content-type': setCurrentPage()
        })
        // Single product page
        if (payload.to.params.parentSku) {
          const currentProduct = store.getters['product/getCurrentProduct']
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Details',
            ecommerce: {
              detail: {
                actionField: {
                  list: 'product detail page'
                },
                products: [getProduct(currentProduct)]
              }
            }
          });
          Vue.prototype.$googleTagManager.detail({
            product: getNextProduct({
              item: currentProduct
            }, store)
          })
        }

        if (payload.to.name.includes('checkout')) {
          // Start checkout
          let step
          if (!payload.to.hash) step = 2
          else if (payload.to.hash === '#shipping') step = 3
          else if (payload.to.hash === '#payment') step = 4
          else if (payload.to.hash === '#orderReview') step = 5
          if (step) {
            GTM.trackEvent(
              checkoutObj(step, state.cart.cartItems, state.checkout.personalDetails)
            );
            Vue.prototype.$googleTagManager.checkout({
              products: getCartItems(state.cart.cartItems, state.route.fullPath, store, config),
              step,
              stateCheckout: state.checkout
            })
          }
        }
      }

      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Add to Cart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });
        Vue.prototype.$googleTagManager.addToCart({
          product: getNextProduct({
            item: payload.product,
            list: 'Product Page'
          }, store)
        })
      }

      // Open cart
      if (type === 'ui/setMicrocart') {
        if (payload) {
          GTM.trackEvent(checkoutObj(1, state.cart.cartItems));
        }
        Vue.prototype.$googleTagManager.checkout({
          products: getCartItems(state.cart.cartItems, state.route.fullPath, store, config),
          step: 1
        })
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Remove from Cart',
          ecommerce: {
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });
        Vue.prototype.$googleTagManager.removeFromCart({
          product: getNextProduct({
            item: payload.product,
            list: 'Microcart'
          }, store)
        })
      }

      // Add product to wishlist
      if (type === 'wishlist/wishlist/ADD') {
        GTM.trackEvent({
          event: 'addToWhishlist',
          eventCategory: 'Engagement',
          eventAction: 'Add to whishlist',
          label: payload.product.name
        });
        Vue.prototype.$googleTagManager.wishlist({
          product: getNextProduct({
            item: payload.product
          }, store)
        })
      }

      // Measuring Views of Promotions
      if (type === 'ui/VIEW_BANNER') {
        if (payload) {
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Promotion',
            eventAction: 'View',
            ecommerce: {
              promoView: {
                promotions: [
                  {
                    name: payload.alt,
                    position: payload.order,
                    creative: payload.image
                  }
                ]
              }
            }
          })
        }
      }
      if (type === 'ui/CLICK_BANNER') {
        if (payload) {
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Promotion',
            eventAction: 'Click',
            ecommerce: {
              promoClick: {
                promotions: [
                  {
                    name: payload.alt,
                    position: payload.order,
                    creative: payload.image
                  }
                ]
              }
            }
          })
        }
      }

      // Added in theme templates:
      // ProductTile:
      // <router-link class="block no-underline product-link" :to="productLink" @click.native="productClick" data-testid="productLink">

      // ProductTileRelated

      // ProductListing:
      // <product-tile :product="product" :position="key" list="catalog"/>

      // Added in banner module:
      // Banner
      // BannerBlock
      // SmallBanner
    })
  }
}
