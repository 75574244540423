import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import cloneDeep from 'lodash-es/cloneDeep'
import config from 'config'

export const homepageStore = {
  namespaced: true,
  state: {
    promo_block: [],
    bestsellers: [],
    news_collection: [],
    sales_collection: [],
    bestsellers_total: [],
    news_collection_total: [],
    sales_collection_total: []
  },
  actions: {
    async loadSales ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({ queryConfig: 'special_price' })
      const { items, total } = await dispatch('product/findProducts', {
        query: newProductsQuery,
        size: 8,
        sort: 'id:desc',
        options: {
          populateRequestCacheTags: true,
          prefetchGroupProducts: false
        }
      }, { root: true })

      commit('SET_LOAD_SALES', items)
      commit('SET_TOTAL_SALES', total)
    },
    async loadNews ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({}) // tutaj powinno byc filrer po bestsellerach { queryConfig: 'news_to_date' }
      newProductsQuery.applyFilter({ key: 'type_id', value: { 'in': ['simple'] } })
      const { items, total } = await dispatch('product/findProducts', {
        query: newProductsQuery,
        size: 12,
        sort: 'id:desc',
        options: {
          populateRequestCacheTags: true,
          prefetchGroupProducts: false
        }
      }, { root: true })
      commit('SET_LOAD_NEWS', items)
      commit('SET_TOTAL_NEWS', total)
    },
    async loadPromoBlock ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({ queryConfig: 'promo_block' })

      const { items } = await dispatch('product/findProducts', {
        query: newProductsQuery,
        size: 8,
        sort: 'id:desc',
        options: {
          populateRequestCacheTags: true,
          prefetchGroupProducts: false
        }
      }, { root: true })

      commit('SET_PROMO_BLOCK', items)
    },
    async loadBestsellers ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({ }) // tutaj powinno byc filrer po bestsellerach { queryConfig: 'bestseller' }
      newProductsQuery.applyFilter({ key: 'type_id', value: { 'in': ['configurable', 'sirent'] } })
      const { items, total } = await dispatch('product/findProducts', {
        query: newProductsQuery,
        size: 12,
        sort: 'sales_count:desc',
        options: {
          populateRequestCacheTags: true,
          prefetchGroupProducts: false
        }
      }, { root: true })
      commit('SET_BESTSELLERS', items)
      commit('SET_TOTAL_BESTSELLERS', total)
    }
  },
  mutations: {
    SET_LOAD_SALES (state, products = []) {
      state.sales_collection = products
    },
    SET_LOAD_NEWS (state, products = []) {
      state.news_collection = products
    },
    SET_PROMO_BLOCK (state, products = []) {
      state.promo_block = products
    },
    SET_BESTSELLERS (state, products = []) {
      state.bestsellers = products
    },
    LOAD_MORE_SALES (state, products = []) {
      state.sales_collection.push(...cloneDeep(products))
    },
    LOAD_MORE_NEWS (state, products = []) {
      state.news_collection.push(...cloneDeep(products))
    },
    LOAD_MORE_PROMO_BLOCK (state, products = []) {
      state.promo_block.push(...cloneDeep(products))
    },
    LOAD_MORE_BESTSELLERS (state, products = []) {
      state.bestsellers.push(...cloneDeep(products))
    },
    SET_TOTAL_SALES (state, total = 0) {
      state.sales_collection_total = total
    },
    SET_TOTAL_NEWS (state, total = 0) {
      state.news_collection_total = total
    },
    SET_TOTAL_BESTSELLERS (state, total = 0) {
      state.bestsellers_total = total
    }
  },
  getters: {
    getSales (state) {
      return state.sales_collection
    },
    getNews (state) {
      return state.news_collection
    },
    getPromoBlockCollection (state) {
      return state.promo_block
    },
    getBestsellers (state) {
      return state.bestsellers
    },
    getSalesTotal (state) {
      return state.sales_collection_total
    },
    getNewsTotal (state) {
      return state.news_collection_total
    },
    getBestsellersTotal (state) {
      return state.bestsellers_total
    }
  }
}
