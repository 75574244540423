import { Wordpress } from '../types/Wordpress'
import { ActionTree } from 'vuex';
import * as types from './mutation-types'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import SearchAdapter from '../lib/searchAdapter'
import rootStore from '@vue-storefront/core/store'

const searchAdapter = new SearchAdapter()

// it's a good practice for all actions to return Promises with effect of their execution
export const actions: ActionTree<Wordpress, any> = {
  // if you want to use cache in your module you can load cached data like this
  async loadPost ({ commit }, { slug }) {
    let storeId = rootStore.state.storeView.storeId
    let query = 'query ($slug: String, $storeId: Int) { blogPosts(store_id: $storeId, filter: { identifier: { eq: $slug }}) { total_count total_pages items { category_id  relatedproduct_id  related_products  related_posts { post_id title identifier } author_id  post_id  content  creation_time publish_time featured_image  filtered_content  identifier  is_active  meta_description  meta_title  short_content  short_filtered_content  title}}}'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { slug, storeId }
    }

    let result = await searchAdapter.search(Request)
    let post = result.data.blogPosts.items[0]
    commit(types.SET_CURRENTPOST, post)
  },
  async loadPosts ({ commit }) {
    let storeId = rootStore.state.storeView.storeId
    let query = 'query ($storeId: Int) { blogPosts(pageSize: 9, currentPage: 1, store_id: $storeId, sortFields: "include_in_recent DESC,post_id DESC" ) { total_count total_pages items { author_id  post_id  content  creation_time publish_time featured_image  filtered_content  identifier  is_active  meta_description  meta_title  short_content  short_filtered_content  title}}}'
    const storeView = currentStoreView()
    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { storeId }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.blogPosts.items
    let allPages = result.data.blogPosts.total_pages
    commit(types.SET_POSTS, { posts, allPages })
  },
  async loadPagePosts ({ commit }, { currentPage }) {
    let storeId = rootStore.state.storeView.storeId
    let query = 'query ($currentPage: Int, $storeId: Int) { blogPosts(pageSize: 9, store_id: $storeId, filter: { category_identifier: { neq: "prasa" } }, currentPage: $currentPage, sortFields: "include_in_recent DESC,post_id DESC") { total_count total_pages items {  author_id  post_id   content  creation_time publish_time featured_image  filtered_content  identifier  is_active  meta_description  meta_title  short_content  short_filtered_content  title}}}'
    const storeView = currentStoreView()
    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { currentPage, storeId }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.blogPosts.items
    let allPages = result.data.blogPosts.total_pages
    commit(types.ADD_POSTS, { posts, allPages, currentPage })
  },
  async loadPress ({ commit }) {
    let storeId = rootStore.state.storeView.storeId
    let query = 'query ($storeId: Int)  { blogPosts(pageSize: 50, filter: { category_identifier: { eq: "prasa" } }, store_id: $storeId, sortFields: "include_in_recent DESC,post_id DESC") { total_count total_pages items {  author_id  post_id page_layout content  creation_time publish_time featured_image  filtered_content  identifier  is_active  meta_description  meta_title  short_content  short_filtered_content  title}}}'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { storeId }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.blogPosts.items
    commit(types.ADD_PRESS, { posts })
  },
  async loadCategoryPosts ({ commit }, { currentPage, slug }) {
    let query = 'query ($currentPage: Int, $slug: String) { blogPosts( pageSize: 9, currentPage: $currentPage, filter: { category_identifier: { eq: $slug } }, sortFields: "include_in_recent DESC,post_id DESC") { total_count total_pages items { author_id  post_id   content  creation_time publish_time featured_image  filtered_content  identifier  is_active  meta_description  meta_title  short_content  short_filtered_content  title}}}'
    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { currentPage, slug }
    }
    let result = await searchAdapter.search(Request)
    let posts = result.data.blogPosts.items
    let allPages = result.data.blogPosts.total_pages
    commit(types.ADD_POSTS, { posts, allPages, currentPage })
  },
  async loadCategories ({ commit, state }) {
    let query = 'query { mpBlogCategories (action: "get_category_list") { total_count pageInfo {  currentPage  endPage  hasNextPage  hasPreviousPage  pageSize  startPage} items {  category_id  children_count  created_at  description  enabled  import_source  level  meta_description  meta_keywords  meta_robots  meta_title  name  parent_id  path  position  store_ids  updated_at  url_key } } }'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: {}
    }

    let result = await searchAdapter.search(Request)

    let categories = result.data.mpBlogCategories.items
    commit(types.SET_CATEGORIES, categories)
  },
  async loadCategoriesPreview ({ commit }) {
    let query = 'query { blogCategories { total_count items {  category_id  posts_count  identifier  title  }}}'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: {}
    }
    let result = await searchAdapter.search(Request)
    commit(types.SET_CATEGORIES_PREVIEW, result.data.blogCategories.items)
  },
  async loadCategoryById ({ commit }, { slug }) {
    let query = 'query ($slug: String) { blogCategories(filter: { identifier: { eq: $slug}}) { items { category_id  posts_count  identifier  title }}}'

    const storeView = currentStoreView()

    const Request = {
      store: storeView.storeCode,
      query: query,
      queryVars: { slug }
    }

    const result = await searchAdapter.search(Request)
    const category = result.data.blogCategories.items[0]
    commit(types.SET_CURRENTCATEGORY, category)
  }
}
