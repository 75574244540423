import { Product, Config, Optional, CategoryResolver, StateCheckout, User } from './interfaces'
import config from 'config'

export let categoryResolver: CategoryResolver
export let currency: string
let language: string

export function setCategoryResolver (resolver: CategoryResolver) {
  categoryResolver = resolver
}

export function indexCategories (menuCategories: any[]) {
  if (!categoryResolver) return
  categoryResolver.setCategories(menuCategories)
}

export function setCurrency (newCurrency: string) {
  currency = newCurrency
}

export function getCurrency (): string {
  if (typeof currency !== 'undefined') {
    return currency
  }
  return 'PLN'
}

export function setLanguage (newLanguage: string) {
  language = newLanguage
}

export function getLanguage (): string {
  if (typeof language !== 'undefined') {
    return language
  }
  return 'pl'
}

export function getDL (): any[] {
  (window as object)['dataLayer'] = window['dataLayer'] || []
  return (window as object)['dataLayer']
}

export function getdeepestCategoryIds (product: Product): string[] | undefined {
  if (typeof product.category === 'undefined' || !categoryResolver) {
    return
  }
  if (!product.category) {
    return [];
  }
  try {
    return categoryResolver.getDeepestCategories(product.category, false)
  } catch (e) {
    return undefined
  }
}

export function getCategoryNames (categoryIds: string[], startingFrom?: number): string[] | undefined {
  if (!categoryResolver) {
    return;
  }
  try {
    return categoryIds.map((x) => categoryResolver && categoryResolver.getNamedPath(x, startingFrom))
  } catch (e) {
    return undefined
  }
}

export function getProductCategories (
  product: Product, includeRaw?: boolean
): {
    category: string,
    product_category_ids: string[] | undefined,
    product_category_names: string[] | undefined
  } {
  if (typeof includeRaw === 'undefined') {
    includeRaw = false
  }
  let categoryIndex = {}
  let mainCategoryIds = getdeepestCategoryIds(product)
  let categoryNames: string[] = []
  let category = ''
  let categoryIds: string[] = []

  if (typeof categoryResolver !== 'undefined') {
    try {
      categoryIndex = categoryResolver.getCategoriesIndex()
      categoryNames = (product.category && product.category.length ? product.category : []).map(
        (x) => categoryIndex[x.category_id] && categoryIndex[x.category_id].name
      ).filter(Boolean) as string[];
      categoryIds = (product.category && product.category.length ? product.category : []).map(
        (x) => x.category_id.toString()
      ).filter(Boolean) as string[];
      if (mainCategoryIds && mainCategoryIds.length) {
        category = categoryResolver.getNamedPath(mainCategoryIds[0])
      }
    } catch (e) {}
  }

  return {
    category: category,
    product_category_ids: includeRaw ? categoryIds : undefined,
    product_category_names: includeRaw ? categoryNames : undefined
  }
}

export function getGaConfig (param: string, defaultValue?: any, restrict?: any[]): any {
  let val = defaultValue
  let noConfig: boolean = typeof config === 'undefined' || typeof config.googleTagManager === 'undefined';
  if (noConfig || typeof config.googleTagManager[param] === 'undefined') {
    return val
  } 
  val = config.googleTagManager[param];
  
  if (typeof restrict !== 'undefined' && !restrict.includes(val)) {
    console.error(`wrong config ${param} param: ${val}, set as ${defaultValue}`);
    return defaultValue
  }
  return val
}

export function getPrice (product: Product): string {
  let param = (getGaConfig('priceParam', 'final', ['original', 'final']) as string)
  let taxInPrice = (getGaConfig('taxInPrice', true) as boolean)
  let paramName = `${param}_price${taxInPrice ? '_incl_tax' : ''}`
  return typeof product[paramName] === 'undefined' ? product.final_price : product[paramName];
}
export function getTax (product: Product): string | undefined {
  let trackTax = (getGaConfig('trackTax', false) as boolean)
  let param = (getGaConfig('priceParam', 'final', ['original', 'final']) as string)
  let paramName = `${param}_price_tax`
  if (!trackTax) return;
  return product[paramName];
}
export function formatPrice (price?: string): string | undefined {
  if (typeof price === 'undefined') {
    return undefined
  }
  return Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function getProductPrices (product: Product): {tax: string | undefined, price: string} {
  let price = getPrice(product);
  return {
    tax: getTax(product),
    price: getPrice(product)
  }
}

export function getProductIds (product: Product): {id: string, dbId?: string, parentDbId?: string, childSku?: string, parentSku?: string} {
  let param = (getGaConfig('idParam', 'sku', ['sku', 'parentSku', 'id', 'parentId']) as string)
  return {
    id: product[param],
    dbId: product.id ? product.id.toString() : undefined,
    parentDbId: product.parentId ? product.parentId.toString() : undefined,
    childSku: product.sku,
    parentSku: product.parentSku
  }
}

export function patchObject (arg: object, optional?: Optional): object {
  if (optional) {
    Object.assign(arg, optional)
  }
  return arg
}

export function adapterUserAddress (user?: User): {country?: string, city?: string} {
  if (!(user && user.addresses && user.addresses.length)) return {};
  let address = user.addresses[0];
  return {
    country: address.country_id,
    city: address.city
  }
}
