// import { VueStorefrontModule, VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
// import { module } from './store'
// export const KEY = 'wishlist'
// const moduleConfig: VueStorefrontModuleConfig = {
//   key: KEY,
//   store: { modules: [{ key: KEY, module }] }
// }
// export const MagentoWishlistExtend = new VueStorefrontModule(moduleConfig)
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { module } from './store'
import { isServer } from '@vue-storefront/core/helpers'
import whishListPersistPlugin from './store/whishListPersistPlugin'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const WishlistModule: StorefrontModule = function ({ store }) {
  StorageManager.init('wishlist')
  store.registerModule('wishlist', module)
  store.subscribe(whishListPersistPlugin)
  if (!isServer) store.dispatch('wishlist/load')
}
