import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SET_POSTS] (state, { posts, allPages }) {
    state.posts = posts
    state.allPages = allPages
    state.currentPage = 1
  },
  [types.ADD_POSTS] (state, { posts, allPages, currentPage }) {
    state.posts = posts
    state.allPages = allPages
    state.currentPage = currentPage
  },
  [types.ADD_PRESS] (state, { posts }) {
    state.press = posts
  },
  [types.SET_CATEGORIES] (state, payload) {
    state.categories = payload
  },
  [types.SET_CURRENTPOST] (state, payload) {
    state.currentPost = payload
  },
  [types.SET_CURRENTCATEGORY] (state, payload) {
    state.currentCategory = payload
  },
  [types.SET_CATEGORIES_PREVIEW] (state, payload) {
    state.categoriesPreview = payload
  }
}
