import i18n from '@vue-storefront/i18n'

const Home = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home.vue')
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue')
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue')
// const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const ProductV2 = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product-v2.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const Career = () => import(/* webpackChunkName: "vsf-career" */ 'theme/pages/Career.vue')
const CmsPage = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/CmsPage.vue')
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
const MyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount.vue')
const ResetPassword = () => import(/* webpackChunkName: "vsf-reset-password" */ 'theme/pages/ResetPassword.vue');
const ThankYouPage = () => import(/* webpackChunkName: "vsf-typ" */ 'theme/pages/ThankYouPage.vue')
const ThankYouForSellingPage = () => import(/* webpackChunkName: "vsf-typ-sale" */ 'theme/pages/ThankYouForSellingPage.vue')
const PostsIndex = () => import(/* webpackChunkName: "vsf-wp-posts-index" */ 'src/modules/vsf-wordpress/pages/posts/Index.vue')
const PressIndex = () => import(/* webpackChunkName: "vsf-wp-press-index" */ 'src/modules/vsf-wordpress/pages/press/Index.vue')
const PostsShow = () => import(/* webpackChunkName: "vsf-wp-posts-show" */ 'src/modules/vsf-wordpress/pages/posts/Show.vue')
const PressShow = () => import(/* webpackChunkName: "vsf-wp-press-show" */ 'src/modules/vsf-wordpress/pages/press/Show.vue')
const Placepozniej = () => import(/* webpackChunkName: "vsf-placepozniej" */ 'theme/pages/Placepozniej.vue')
const Selling = () => import(/* webpackChunkName: "vsf-selling" */ 'theme/pages/Selling.vue')

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'career', path: '/kariera', component: Career },
  { name: 'eur_pl-career', path: '/kariera', component: Career },
  { name: 'pln_en-career', path: '/career', component: Career },
  { name: 'eur_en-career', path: '/career', component: Career },
  { name: 'pln_de-career', path: '/karriere-de', component: Career },
  { name: 'eur_de-career', path: '/karriere-de', component: Career },
  { name: 'thank-you-page', path: '/thank-you-page', component: ThankYouPage, props: (route) => ({ orderIncrementId: route.query.order_increment_id, error: route.query.error, status: route.query.status }) },
  { name: 'thank-you-for-selling-page', path: '/thank-you-for-selling-page', component: ThankYouForSellingPage },
  // { name: 'order-tracking', path: '/order-tracking', component: Static, props: { page: 'lorem', title: 'Track my Order' } },
  { name: 'my-account', path: '/my-account', component: MyAccount, meta: { requiresAuth: true } },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: MyAccount, props: { activeBlock: 'MyShippingDetails' }, meta: { requiresAuth: true } },
  // { name: 'my-newsletter', path: '/my-account/newsletter', component: MyAccount, props: { activeBlock: 'MyNewsletter' }, meta: { requiresAuth: true } },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: { activeBlock: 'MyOrders' }, meta: { requiresAuth: true } },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' }, meta: { requiresAuth: true } },
  { name: 'my-recently-viewed', path: '/my-account/recently-viewed', component: MyAccount, props: { activeBlock: 'MyRecentlyViewed' }, meta: { requiresAuth: true } },
  { name: 'my-subscriptions', path: '/my-account/subscriptions', component: MyAccount, props: { activeBlock: 'MySubscriptions' }, meta: { requiresAuth: true } },
  { name: 'my-active-subscriptions', path: '/my-account/active-subscriptions', component: MyAccount, props: { activeBlock: 'MyActiveSubscriptions' }, meta: { requiresAuth: true } },
  { name: 'my-subscription-cards', path: '/my-account/cards', component: MyAccount, props: { activeBlock: 'MySubscriptionCards' }, meta: { requiresAuth: true } },
  { name: 'my-selling-products', path: '/my-account/my-selling-products', component: MyAccount, props: { activeBlock: 'MySellingProducts' }, meta: { requiresAuth: true } },
  { name: 'selling', path: '/selling', component: Selling },
  { name: 'error', path: '/error', component: ErrorPage },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'sirent-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'single_number-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'subscription-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: ProductV2 }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'posts-index', path: '/blog', component: PostsIndex, props: { title: 'Blog' } },
  { name: 'posts-show', path: '/blog/post/:slug', component: PostsShow },
  { name: 'press-index', path: '/prasa', component: PressIndex, props: { title: 'Prasa' } },
  { name: 'press-show', path: '/prasa/post/:slug', component: PressShow },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: CmsPage },
  { name: 'create-password', path: '/create-password', component: ResetPassword },
  { name: 'placepozniej', path: '/placepozniej', component: Placepozniej },
  { name: 'page-not-found', path: '*', component: PageNotFound }
]

export default routes
