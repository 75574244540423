/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { DeepestCategoryStrategy } from './DeepestCategoryStrategy';

export class AlgoStrategy implements DeepestCategoryStrategy {
  execute (paths: string[]) {
    if (paths.length < 2) return paths;
    let indexedPaths: { path: string, i: number }[] = paths.map((path) => {
      return { path: path, i: path.split('/').length };
    });
    indexedPaths.sort((left, right) => right.i - left.i);
    let len: number = indexedPaths.length;

    let toDel: Set<number> = new Set();
    for (let i = 0; i < len - 1; i++) {
      if (toDel.has(i)) continue;
      let curPath = indexedPaths[i].path;
      for (let j = i + 1; j < len; j++) {
        let subPath = indexedPaths[j].path;
        if (curPath.startsWith(subPath)) toDel.add(j);
      }
      indexedPaths = indexedPaths.filter((x, j) => !toDel.has(j));
      len = indexedPaths.length;
    }
    return indexedPaths.map((x) => x.path);
  }
}
