import rootStore from '@vue-storefront/core/store'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n'
import config from 'config'

function isCanBeReached (step: string) {
  if (
    step === 'register' &&
    !rootStore.getters['user/isLoggedIn']
  ) {
    return true
  } else if (
    step === 'product' &&
    rootStore.getters['user/isLoggedIn']
  ) {
    return true
  } else if (
    step === 'shipping' &&
    rootStore.getters['user/isLoggedIn'] &&
    rootStore.state.selling.stepsValidation.product
  ) {
    return true
  } else if (
    step === 'confirm' &&
    rootStore.getters['user/isLoggedIn'] &&
    rootStore.state.selling.stepsValidation.product &&
    rootStore.state.selling.stepsValidation.shipping
  ) {
    return true
  }
  return false
}

function replaceSpecialCharacters (text, substitute) {
  return [...text].map(char =>
    !/^[A-Za-z0-9]*$/.test(char)
      ? substitute
      : char
  ).join('')
}

function prepareMultiselectValues (arr) {
  return arr.map(item => item.value)
}

function prepareData (prop, key) {
  if (key === 'marketplace_price') {
    return [{
      amount: prop,
      currency: currentStoreView().i18n.currencyCode
    }]
  }
  return Array.isArray(prop) ? prepareMultiselectValues(prop) : prop
}

function prepareValues (product, langCode, fullAttributesMap) {
  const resultObj = {}
  Object.keys(fullAttributesMap).forEach(key => {
    if (!product[key]) return
    resultObj[key] = [{
      locale: fullAttributesMap[key].localizable ? langCode : null,
      scope: null,
      data: prepareData(product[key], key)
    }]
  })
  return resultObj
}

function prepareIdentifier (product) {
  return `${replaceSpecialCharacters(product.name, '_')}_${new Date().valueOf()}`
}

function getCurrentFamily (categoryCode: string): string {
  const categoryToFamilyMap = {
    [config.akeneo.category_ids.bizuteria]: 'Marketplace_jewelry',
    [config.akeneo.category_ids.torebki]: 'Marketplace_bags',
    [config.akeneo.category_ids.marynarki]: 'Marketplace_jackets',
    [config.akeneo.category_ids.sukienki]: 'Marketplace_dress',
    [config.akeneo.category_ids.plaszcze]: 'Marketplace_jackets',
    [config.akeneo.category_ids.spodnie]: 'Marketplace_pants',
    [config.akeneo.category_ids.bluzki]: 'Marketplace_shirts',
    [config.akeneo.category_ids.spodnice]: 'Marketplace_pants',
    [config.akeneo.category_ids.kombinezony]: 'Marketplace_pants',
    [config.akeneo.category_ids.buty]: 'Marketplace_shoes'
  }
  return categoryToFamilyMap[categoryCode]
    ? categoryToFamilyMap[categoryCode]
    : ''
}

function prepareProducts (products, langCode, fullAttributesMap) {
  let result = []
  products.forEach(product => {
    const preparedCategories = prepareMultiselectValues(product.categories)
    result.push({
      identifier: product.identifier,
      categories: preparedCategories,
      family: getCurrentFamily(preparedCategories[0]),
      values: prepareValues(product, langCode, fullAttributesMap)
    })
  })
  return result
}

function sortAttrsWithMap (arr, mapOrder) {
  return arr.sort((leftName, rightName) => {
    const leftNameIndex = mapOrder.indexOf(leftName)
    const rightNameIndex = mapOrder.indexOf(rightName)
    if (leftNameIndex < 0 && rightNameIndex >= 0) return 1
    if (rightNameIndex < 0 && leftNameIndex >= 0) return -1
    if (leftNameIndex < rightNameIndex) return -1
    if (leftNameIndex > rightNameIndex) return 1
    return 0
  })
}

function prepareProductsCountString (productsCount) {
  if (productsCount === null) return `0 ${i18n.t('products')}`
  if (currentStoreView().i18n.defaultLocale === 'pl-PL' && productsCount >= 0) {
    if (productsCount === 1) return `${productsCount} ${i18n.t('produkt')}`
    const lastNumber = productsCount % 10
    if (lastNumber >= 2 && lastNumber <= 4) return `${productsCount} ${i18n.t('produkty')}`
    else return `${productsCount} ${i18n.t('products')}`
  }
  return `${productsCount} ${i18n.t('products')}`
}

function isMarketplaceProductSold (soldDate, productTypeId) {
  if (!soldDate || productTypeId !== 'simple') return false
  const parsedSoldDate = new Date(soldDate.replace(/-/g, '/'))
  return new Date().valueOf() > parsedSoldDate.valueOf()
}

export { isCanBeReached, prepareProducts, prepareProductsCountString, prepareIdentifier, getCurrentFamily, sortAttrsWithMap, isMarketplaceProductSold }
