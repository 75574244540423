import {
  setCategoryResolver,
  indexCategories,
  setCurrency,
  setLanguage
} from './common'
import {
  GATracker,
  CategoryResolver
} from './interfaces';

export let tracker: GATracker

export interface ConfigData {
  resolver?: CategoryResolver,
  categories?: any[],
  currency?: string,
  language?: string
}

export function config (
  {
    resolver = undefined,
    categories = undefined,
    currency = undefined,
    language = undefined
  }: ConfigData
) {
  resolver && setCategoryResolver(resolver)
  categories && indexCategories(categories)
  currency && setCurrency(currency)
  language && setLanguage(language)
}
