import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import UserState from '../types/UserState'
import { Logger } from '@vue-storefront/core/lib/logger'

const mutations: MutationTree<UserState> = {
  [types.USER_TOKEN_CHANGED] (state, payload) {
    state.token = payload.newToken
    if (payload.meta && payload.meta.refreshToken) {
      state.refreshToken = payload.meta.refreshToken // store the refresh token
      Logger.log('Refresh token is set to' + state.refreshToken, 'user')()
    }
  },
  [types.USER_START_SESSION] (state) {
    state.session_started = new Date()
  },
  [types.USER_SET_BALANCE] (state, balance) {
    state.balance = balance
  },
  [types.USER_SET_CARDS] (state, cards) {
    state.cards = cards
  },
  [types.USER_SET_EXPIRATION] (state, expiration) {
    state.expiration = expiration
  },
  [types.USER_SET_HISTORY] (state, history) {
    state.history = history
  },
  [types.USER_SET_SUBSCRIPTIONS] (state, subscriptions) {
    state.subscriptions = subscriptions
  },
  [types.USER_GROUP_TOKEN_CHANGED] (state, token) {
    state.groupToken = token
  },
  [types.USER_GROUP_CHANGED] (state, groupId) {
    state.groupId = groupId
  },
  [types.USER_INFO_LOADED] (state, currentUser) {
    if (currentUser) {
      if (!currentUser.custom_attributes) {
        currentUser.custom_attributes = []
      }
      const bankAccountObject = currentUser.custom_attributes.find(attr => attr.attribute_code === 'bank_account_number')
      if (!bankAccountObject) {
        currentUser.custom_attributes.push({
          attribute_code: 'bank_account_number',
          value: null
        })
      }
    }
    state.current = currentUser
  },
  [types.USER_ORDERS_HISTORY_LOADED] (state, ordersHistory) {
    state.orders_history = ordersHistory
  },
  [types.USER_END_SESSION] (state) {
    state.token = ''
    state.current = null
    state.session_started = null
  },
  [types.USER_LOCAL_DATA_LOADED] (state, readed = false) {
    state.local_data_loaded = readed
  }
}

export default mutations
