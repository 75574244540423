import { SearchQuery } from 'storefront-query-builder'
import config from 'config'

export function prepareQuery ({ queryText = '', filters = [], queryConfig = '' }) {
  let query = new SearchQuery()
  // prepare filters and searchText
  if (filters.length === 0 && queryConfig !== '') {
    // try get filters from config
    if (config.hasOwnProperty('query') && config.query.hasOwnProperty(queryConfig) && config.query[queryConfig].hasOwnProperty('filter')) {
      filters = config.query[queryConfig].filter
    }
  }

  if (queryText === '') {
    // try to get searchText from config
    if (config.hasOwnProperty('query') && config.query.hasOwnProperty(queryConfig) && config.query[queryConfig].hasOwnProperty('searchText')) {
      queryText = config.query[queryConfig].searchText
    }
  }

  // Process filters and searchText if exists
  if (filters.length > 0) {
    filters.forEach(filter => {
      query = query.applyFilter({ key: filter.key, value: filter.value }) // Tees category
    })
  }

  if (queryText !== '') {
    query = query.setSearchText(queryText)
  }

  // 'promo_block' -> 'polecamy' homepage
  if (queryConfig === 'promo_block') {
    query = query.applyFilter({ key: 'promo_block', value: { 'eq': 1 } })
  }

  // sales block homepage
  if (queryConfig === 'special_price') {
    query = query.applyFilter({ key: 'range', value: { 'multiply': { special_price: { 'gt': 0 }, special_to_date: { 'gte': 'now/d' } } } })
    query = query.applyFilter({ key: 'range', value: { 'multiply': { 'configurable_children.special_price': { 'gt': 0 }, 'configurable_children.special_to_date': { 'gte': 'now/d' } } } })
  }

  // news block homepage
  if (queryConfig === 'news_to_date') {
    // query = query.applyFilter({ key: 'news_to_date', value: { 'gte': 'now/d' } })
    query = query.applyFilter({ key: 'range', value: { 'multiply': { news_to_date: { 'gte': 'now/d' }, news_from_date: { 'lte': 'now/d' } } } })
  }

  // bestseller block homepage
  if (queryConfig === 'bestseller') {
    query = query.applyFilter({ key: 'bestseller', value: { 'eq': 1 } })
  }
  // Add basic filters
  query = query
    .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } })

  return query
}
