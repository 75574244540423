import { getters } from './../../../../core/modules/url/store/getters';
import { processURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import uniqBy from 'lodash-es/uniqBy'

export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    isAuthElem: false,
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    checkoutTypes: ['Osoba prywatna (paragon)', 'Firma (faktura VAT)'],
    selectedCheckoutType: 'Osoba prywatna (paragon)',
    isNipDataResponse: '',
    isSaleFilterActive: false,
    isNewFilterActive: false,
    isQueryFilterActive: false,
    isBestselleryFilterActive: false,
    isSubscriptionFilterActive: false,
    isShowroomFilterActive: false,
    isVideoFilterActive: false,
    outboundLink: {},
    selectedBanner: {},
    viewedBanner: {},
    selectedBillingId: null,
    selectedShippingId: null,
    payuMethodType: {},
    mobileFilter: false,
    allowedCountries: [],
    pickupLocation: {
      city: '',
      country: '',
      zipCode: '',
      state: '',
      region_id: '',
      streetAddress: '',
      apartmentNumber: ''
    },
    pickupAdditional: {
      pickupName: '',
      pickupDescription: ''
    },
    selectedCountry: ''
  },
  mutations: {
    setSelectedCountry (state, value) {
      state.selectedCountry = value
    },
    setAllowedCounties (state, value) {
      state.allowedCountries = state.allowedCountries.concat(value)
    },
    setPickupLocation (state, value) {
      state.pickupLocation = value
    },
    setPickupAdditional (state, value) {
      state.pickupAdditional = value
    },
    routeChanged () {
    },
    toogleMobileFilter (state, value) {
      state.mobileFilter = value
    },
    setPayuMethod (state, payload) {
      state.payuMethodType = payload
    },
    setBillingId (state, payload) {
      state.selectedBillingId = payload
    },
    setShippingId (state, payload) {
      state.selectedShippingId = payload
    },
    openOutboundLink (state, payload) {
      state.outboundLink = payload
    },
    setQueryFilter (state, action) {
      state.isQueryFilterActive = action === true
    },
    setNewFilter (state, action) {
      state.isNewFilterActive = action === true
    },
    setBestselleryFilter (state, action) {
      state.isBestselleryFilterActive = action === true
    },
    setSubscriptionFilter (state, action) {
      state.isSubscriptionFilterActive = action === true
    },
    setShowroomFilter (state, action) {
      state.isShowroomFilterActive = action === true
    },
    setVideoFilter (state, action) {
      state.isVideoFilterActive = action === true
    },
    setSaleFilter (state, action) {
      state.isSaleFilterActive = action === true
    },
    setNipDataResponse (state, action) {
      state.isNipDataResponse = action
    },
    setCheckoutType (state, action) {
      state.selectedCheckoutType = action
    },
    clearAllModals (state) {
      state.searchpanel = false
      state.wishlist = false
      state.sidebar = false
      state.microcart = false
      state.isAuthElem = false
    },
    clearAuthModals (state) {
      state.isAuthElem = false
    },
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    // for GTM banner track
    VIEW_BANNER (state, payload) {
      state.viewedBanner = payload
    },
    CLICK_BANNER (state, payload) {
      state.selectedBanner = payload
    },
    setMicrocart (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.sidebar = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      uiStore.mutations.clearAllModals(state)
      state.wishlist = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      if (state.isAuthElem === true) {
        state.isAuthElem = false
      } else {
        state.isAuthElem = true
      }
      state.searchpanel = false
      state.wishlist = false
      state.sidebar = false
      state.microcart = false
      state.authElem = action
    }
  },
  actions: {
    getPickupLocation ({ commit }) {
      return new Promise((resolve, reject) => {
        const url = processURLAddress(getApiEndpointUrl(config.cart, 'getPickupLocation_endpoint'))
        TaskQueue.execute({
          url,
          payload: {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            },
            mode: 'cors'
          }
        }).then(resp => {
          try {
            if (resp.code !== 200) reject(resp)
            Logger.info('Pickup location loaded')()
            if (!resp.result || !resp.result.items || !resp.result.items.length) reject('Pickup location is empty')
            const pickuplocation = resp.result.items[0]
            const streetAddress = pickuplocation.street && pickuplocation.street.split(' ')[0]
            const apartmentNumber = pickuplocation.street && pickuplocation.street.split(' ')[1]
            if (!streetAddress || !apartmentNumber) reject('Street address is wrong')
            commit('setPickupLocation', {
              city: pickuplocation.city,
              country: pickuplocation.country_id,
              zipCode: pickuplocation.postcode,
              state: pickuplocation.region,
              region_id: pickuplocation.region_id,
              streetAddress,
              apartmentNumber
            })
            commit('setPickupAdditional', {
              pickupName: pickuplocation.name,
              pickupDescription: pickuplocation.description
            })
            resolve(resp.result)
          } catch (e) {
            reject('Pickup address is wrong')
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getAllowedCountries ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = processURLAddress(getApiEndpointUrl(config.cart, 'allowedcountries_endpoint').replace('{{websiteId}}', payload))
        TaskQueue.execute({
          url,
          payload: {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            },
            mode: 'cors'
          }
        }).then(resp => {
          if (resp.code === 200) {
            Logger.info('Countries list loaded')()
            if (resp.result) {
              let uniqCountries = uniqBy(resp.result, 'id')
              let dataToSave = uniqCountries.map(country => { return { id: country.id, name: country.full_name_english, websiteId: payload } })
              commit('setAllowedCounties', dataToSave)
              resolve(resp.result)
            } else {
              reject('Booked data is empty')
            }
          } else {
            reject(resp)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    toogleMobileFilter ({ state, commit }, payload) {
      uiStore.mutations.clearAllModals(state)
      commit('toogleMobileFilter', payload)
      state.overlay = payload === true
      let body = document.getElementsByTagName('body')[0]
      if (payload === true) {
        body.classList.add('iphone-scroll')
      } else {
        body.classList.remove('iphone-scroll')
      }
    },
    setPayuMethod ({ commit }, payload) {
      commit('setPayuMethod', payload)
    },
    toggleQueryFilter ({ commit, state }, payload) {
      commit('setQueryFilter', !state.isQueryFilterActive)
      commit('setQueryText', payload)
    },
    resetQueryFilter ({ commit, state }) {
      commit('setQueryFilter', false)
      commit('setQueryText', '')
    },
    toggleNewFilter ({ commit, state }) {
      commit('setNewFilter', !state.isNewFilterActive)
    },
    toggleBestselleryFilter ({ commit, state }) {
      commit('setBestselleryFilter', !state.isBestselleryFilterActive)
    },
    toggleSubscriptionFilter ({ commit, state }) {
      commit('setSubscriptionFilter', !state.isSubscriptionFilterActive)
    },
    toggleShowroomFilter ({ commit, state }) {
      commit('setShowroomFilter', !state.isShowroomFilterActive)
    },
    toggleVideoFilter ({ commit, state }) {
      commit('setVideoFilter', !state.isVideoFilterActive)
    },
    toggleSaleFilter ({ commit, state }) {
      commit('setSaleFilter', !state.isSaleFilterActive)
    },
    resetSaleFilter ({ commit, state }) {
      commit('setSaleFilter', false)
    },
    resetNewFilter ({ commit, state }) {
      commit('setNewFilter', false)
    },
    changeNipDataResponse ({ commit }, data) {
      commit('setNipDataResponse', data)
    },
    changeCheckoutType ({ commit }, data) {
      commit('setCheckoutType', data)
    },
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    },
    closeWishlist ({ commit, state }) {
      if (state.wishlist) commit('setWishlist', false)
    }
  },
  getters: {
    isMobileFitersOpen: (state) => state.mobileFilter,
    pickupLocation: (state) => state.pickupLocation,
    pickupAdditional: (state) => state.pickupAdditional,
    allowedCountries: (state) => state.allowedCountries
  }
}
