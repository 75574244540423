import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export function getSearchQuery (filters): string {
  const result = filters.map(filter => filter.productProperty)
  // TODO add groupBy by filter types
  return `{"marketplace_status":[{"operator":"IN","value":${JSON.stringify(result)}}]}`
}

export function getLocale (): string {
  return currentStoreView().i18n.defaultLocale.replace('-', '_')
}
