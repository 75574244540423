<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal')

export default {
  data () {
    return {
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout
  }
}
</script>

<style lang="scss">
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$black: color(black);

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  /* background: #e0e0e0; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $black;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $black;
}
</style>
