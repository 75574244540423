import debounce from 'lodash/debounce'

const options = {
  data: () => ({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight
  }),
  methods: {
    onResize () {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
    }
  },
  created () {
    window.addEventListener('resize', debounce(this.onResize.bind(this), 50));
  },
  computed: {
    isSmallMobile () {
      return (this.innerWidth <= 580)
    },
    isMobile () {
      return (this.innerWidth <= 767)
    },
    isTablet () {
      return (this.innerWidth > 767 && this.innerWidth <= 1024)
    },
    isSmallDesktop () {
      return (this.innerWidth > 1025 && this.innerWidth <= 1200)
    }
  }
}
// should be used in core/app.ts

const MobileDetected = {
  install: function (Vue) {
    // do not register twice
    if (Vue.prototype.$device) return

    if (typeof window !== 'undefined') {
      Vue.prototype.$device = new Vue(options)
    } else {
      // during SSR we're not able to check anything, so let's just return false everywhere
      Vue.prototype.$device = {
        isSmallMobile: false,
        isMobile: false,
        isTablet: false,
        isSmallDesktop: false
      }
    }
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(MobileDetected)
}

export default MobileDetected
