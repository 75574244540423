/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { CategoryResolverConfig } from './CategoryResolverConfig'
import { InputCategory, InputCompleteCategory } from './interfaces/input'
import { traverse } from './utils/object'

export interface CategoryResolverIndex {
  [key: number]: { path: string, name: string }
}
export class CategoryResolver {
  private static instance?: CategoryResolver
  private static config?: CategoryResolverConfig
  categoriesIndex?: CategoryResolverIndex
  constructor () {
    if (CategoryResolver.instance !== undefined) {
      throw new Error('Resolver was already instantiated');
    }
    CategoryResolver.instance = this
  }
  static get (config?: CategoryResolverConfig): CategoryResolver {
    if (typeof config !== 'undefined') {
      CategoryResolver.configure(config)
    }
    if (typeof CategoryResolver.instance !== 'undefined') {
      return CategoryResolver.instance
    }
    CategoryResolver.instance = new CategoryResolver()
    return CategoryResolver.instance
  }
  public static configure (config: CategoryResolverConfig) {
    CategoryResolver.config = config
  }
  get config (): CategoryResolverConfig {
    if (typeof CategoryResolver.config === 'undefined') {
      CategoryResolver.config = new CategoryResolverConfig()
    }
    return CategoryResolver.config
  }
  set config (config: CategoryResolverConfig) {
    CategoryResolver.config = config
  }
  static indexCategories (
    categories: InputCompleteCategory[]
  ): {[key: number]: {path: string, name: string}} {
    const res: { [key: number]: { path: string, name: string } } = {}
    traverse(categories, (x: InputCompleteCategory | InputCompleteCategory[]) => {
      if (Array.isArray(x)) { return x }
      if (x.children_data && x.children_data.length) { return x.children_data }
      return []
    },
    (x: InputCompleteCategory | InputCompleteCategory[]) => {
      if (Array.isArray(x)) { return }
      if (!(x.id && x.path && x.name)) { return }
      res[x.id] = { path: x.path, name: x.name }
    }
    )
    return res
  }
  getCategoriesIndex (): CategoryResolverIndex {
    if (typeof this.categoriesIndex === 'undefined') { throw new Error('Categories were not set'); }
    return this.categoriesIndex
  }
  setCategories (categories: InputCompleteCategory[], update: boolean = false) {
    if (update || this.categoriesIndex === undefined) { this.categoriesIndex = CategoryResolver.indexCategories(categories) }
  }
  getDeepestCategories (
    categoriesList: InputCategory[],
    pretty: boolean = true,
    categories?: InputCompleteCategory[],
    update: boolean = false
  ): string[] {
    if (categories) { this.setCategories(categories, update) }

    const config: CategoryResolverConfig = this.config
    // categories to paths
    let paths: string[] = categoriesList.map(
      (x) => {
        const catIndex = this.getCategoriesIndex()
        if (typeof x.category_id === 'undefined') { throw new Error(`Category was not provided`) }
        if (typeof catIndex[x.category_id] === 'undefined') {
          if (config.skipCategoriesNotInIndex) return null
          throw new Error(`Can not find category ${x.category_id}`)
        }

        return catIndex[x.category_id].path
      }
    ).filter((x) => x !== null) as string[]
    paths = config.deepestCategoryStrategy.execute(paths)
    if (pretty) { paths = paths.map((x) => this.getNamedPath(x)) }
    return paths
  }
  getNamedPath (path: string, startingFrom?: number): string {
    const config = this.config
    if (typeof startingFrom === 'undefined') { startingFrom = config.cropCategoriesBefore }
    return path.split('/').slice(startingFrom).map(
      (x) => this.idToName(x)
    ).filter(
      (x) => x !== null
    ).join('/')
  }
  idToName (categoryId: string | number): string | null{
    const catIndex = this.getCategoriesIndex()
    const config = this.config
    if (typeof catIndex[categoryId] === 'undefined') {
      if (config.skipCategoriesNotInIndex) { return null }
      throw new Error('Can not map corresponding path')
    }
    return catIndex[categoryId].name ? catIndex[categoryId].name : null
  }
  idToPathName (category_id: string | number) {
    const catIndex = this.getCategoriesIndex()
    const config = this.config
    if (typeof catIndex[category_id] === 'undefined') {
      if (config.skipCategoriesNotInIndex) return null
      throw new Error(`Can not find category ${category_id}`)
    }
    return this.getNamedPath(catIndex[category_id].path)
  }
}
