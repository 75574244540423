import rootStore from '@vue-storefront/core/store'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import cloneDeep from 'lodash-es/cloneDeep'
import { Logger } from '@vue-storefront/core/lib/logger'
import { AkeneoService } from '@vue-storefront/core/data-resolver'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { prepareProducts, getCurrentFamily, sortAttrsWithMap } from 'theme/helpers/selling-products'
import i18n from '@vue-storefront/i18n'
import { Module } from 'vuex'
import { SellingStore } from 'theme/store/types/Marketplace'
import Vue from 'vue'

export const sellingStore: Module<SellingStore, any> = {
  namespaced: true,
  state: {
    addedProductsCount: null,
    fullAttributeCodesList: [],
    allCategories: [],
    dropdownAttrsOptions: {},
    fullAttributesMap: {},
    additionalProductAttrsList: {
      uploadedImages: {
        image: null,
        back_image: null,
        side_image: null,
        label_image: null
      }
    },
    steps: {
      register: false,
      product: false,
      shipping: false,
      confirm: false
    },
    stepsValidation: {
      product: false,
      shipping: false
    },
    products: [],
    preparedProducts: [],
    pimSelectorMap: {
      pim_catalog_simpleselect: 'baseSelect',
      pim_catalog_multiselect: 'multiSelect',
      pim_catalog_textarea: 'baseTextarea',
      pim_catalog_text: 'baseInput',
      pim_catalog_price_collection: 'number',
      pim_catalog_identifier: 'text'
    },
    isLastPage: false,
    productsPerPage: 10,
    currentPage: 1,
    allProductsCount: null,
    marketplaceStatusClass: {
      success: ['active'],
      error: ['rejected'],
      'in-progress': ['verification']
    }
  },
  getters: {
    getActiveStep: state => {
      let activeKeys = Object.keys(state.steps).filter(step => state.steps[step])
      return Array.isArray(activeKeys) && activeKeys.length > 0
        ? activeKeys[0]
        : null
    },
    getActiveStepIndex: (state, getters) => Object.keys(state.steps).findIndex(step => step === getters.getActiveStep),
    getSteps: state => state.steps,
    getAddedProductsCount: state => state.addedProductsCount,
    getTotalProductsPrice: state => state.products.reduce((total, product) => total + Number(product.marketplace_price), 0),
    getTotalProductsProfit: (state, getters) => getters.getTotalProductsPrice * config.akeneo.profitFactor,
    getProducts: state => state.products,
    getPrettyProducts: state => {
      return state.products.length && state.products.map(product => ({
        identifier: product && product.identifier,
        image: product && product.values && product.values.image && product.values.image[0] && product.values.image[0].data,
        name: product && product.values && product.values.name && product.values.name[0] && product.values.name[0].data,
        marketplace_price: product && product.values && product.values.marketplace_price && product.values.marketplace_price[0] && product.values.marketplace_price[0].data[0] && product.values.marketplace_price[0].data[0].amount,
        marketplace_status: product && product.values && product.values.marketplace_status && product.values.marketplace_status[0] && product.values.marketplace_status[0].data,
        marketplace_status_class: product && product.values && product.values.marketplace_status && product.values.marketplace_status[0] && product.values.marketplace_status[0].data && Object.keys(state.marketplaceStatusClass).find(key => state.marketplaceStatusClass[key].includes(product.values.marketplace_status[0].data)),
        created: product && product.created,
        size: product && product.values && product.values.size && product.values.size[0] && product.values.size[0].data,
        designer: product && product.values && product.values.designer && product.values.designer[0] && product.values.designer[0].data,
        basic_color: product && product.values && product.values.basic_color && product.values.basic_color[0] && product.values.basic_color[0].data
      }))
    },
    getProductsCount: state => state.products.length,
    getShippingName: (state) => (code, langCode) => {
      const delivery = state.dropdownAttrsOptions && state.dropdownAttrsOptions.marketplace_delivery.find(delivery => delivery.code === code)
      if (delivery) return delivery.labels[langCode]
    },
    getCurrentShippingCode: state => state.products[0] && state.products[0].marketplace_delivery,
    getCurrentShippingName: (state, getters) => (langCode) => {
      if (getters.getCurrentShippingCode && state.dropdownAttrsOptions) {
        const delivery = state.dropdownAttrsOptions.marketplace_delivery.find(option => option.code === getters.getCurrentShippingCode)
        if (delivery) return delivery.labels[langCode]
      }
    },
    getAllCategories: state => state.allCategories,
    getFullAttributesMap: state => state.fullAttributesMap,
    getSortedProductAttributesNames: state => {
      const SKIPPED_ATTRIBUTES = [
        'product_form_lang',
        'marketplace_status',
        'marketplace_delivery',
        'sku',
        'vimeo_id',
        'additional_image_1',
        'additional_image_2',
        'back_image',
        'Front_Image',
        'hover_image_2',
        'image',
        'label_image',
        'meta_keyword',
        'side_image',
        'small_image',
        'meta_description',
        'short_description',
        'thumbnail',
        'user_email',
        'accepted',
        'stylists_notes',
        'editors_notes',
        'fabric',
        'maternity',
        'for_weather',
        'age_range',
        'features',
        'neckline',
        'visibility',
        'retail_price',
        'user_id',
        'meta_title',
        'measurements',
        'additional_image_3',
        'additional_Image_4'
      ]
      const SORT_ORDER = [
        'name',
        'basic_color',
        'designer',
        'fabric',
        'marketplace_condition',
        'marketplace_purchase_year',
        'purchase_place',
        'size',
        'shoe_size',
        'marketplace_price'
      ]
      const allAttributesNames = Object.keys(state.fullAttributesMap)
      const filteredAttributesNames = allAttributesNames.filter(name => !SKIPPED_ATTRIBUTES.includes(name))
      const sortedAttributesNames = sortAttrsWithMap(filteredAttributesNames, SORT_ORDER)
      return sortedAttributesNames
    },
    getPreparedProductAttribures: state => langCode => {
      const productAttribures = {}
      for (const attribute in state.fullAttributesMap) {
        const attributeValue = state.fullAttributesMap[attribute]
        if (!attributeValue.labels || !attributeValue.inputType) continue
        productAttribures[attribute] = {
          label: attributeValue.labels[langCode],
          inputType: attributeValue.inputType
        }
      }
      return productAttribures
    },
    getPreparedDropdownAttrsOptions: state => langCode => {
      const preparedAttribureOptions = {}
      Object.keys(state.dropdownAttrsOptions).forEach(attribute => {
        state.dropdownAttrsOptions[attribute].sort((a, b) => a.sort_order - b.sort_order)
        state.dropdownAttrsOptions[attribute].forEach(option => {
          if (!option.labels[langCode]) return
          let attributeOption: any = {
            label: option.labels[langCode],
            value: option.code
          }
          if (preparedAttribureOptions[attribute]) {
            preparedAttribureOptions[attribute].push(attributeOption)
          } else {
            preparedAttribureOptions[attribute] = [attributeOption]
          }
        })
      })
      return preparedAttribureOptions
    },
    getPreparedCategoriesOptions: state => langCode => {
      const preparedCategoriesOptions = []
      state.allCategories.forEach(option => {
        if (!option.labels[langCode]) return
        let category = {
          label: option.labels[langCode],
          value: option.code
        }
        preparedCategoriesOptions.push(category)
      })
      return preparedCategoriesOptions
    },
    getDropdownAttrsOptions: state => state.dropdownAttrsOptions,
    getDropdownAttrsList: state => Object.keys(state.fullAttributesMap).filter(key =>
      state.fullAttributesMap[key].inputType === 'baseSelect' ||
      state.fullAttributesMap[key].inputType === 'multiSelect'
    ),
    getIsLastPage: state => state.isLastPage,
    getProductsPerPage: state => state.productsPerPage,
    getCurrentPage: state => state.currentPage,
    getAllProductsCount: state => state.allProductsCount
  },
  mutations: {
    setFullAttributesMap (state, payload) {
      if (payload._embedded && payload._embedded.items) {
        let newFullAttributesMap: any = {}
        payload._embedded.items.forEach(item => {
          newFullAttributesMap[item.code] = {
            localizable: item.localizable,
            inputType: state.pimSelectorMap[item.type],
            labels: item.labels
          }
        });
        state.fullAttributesMap = Object.assign({}, newFullAttributesMap)
      }
    },
    setCategories (state, payload) {
      if (payload._embedded && payload._embedded.items) {
        state.allCategories = payload._embedded.items.filter(item => item.parent === config.akeneo.category_ids.parent)
      }
    },
    setDropdownAttrsOptions (state, { code, result }) {
      let newDropdownAttrsOptions = {}
      if (result._embedded && result._embedded.items) {
        newDropdownAttrsOptions[code] = result._embedded.items
      }
      state.dropdownAttrsOptions = Object.assign({}, state.dropdownAttrsOptions, newDropdownAttrsOptions)
    },
    setStep (state, payload) {
      state.steps[payload] = true
    },
    setFullAttributeCodesList (state, payload) {
      state.fullAttributeCodesList = payload
    },
    setAddedProductsCount (state, payload) {
      state.addedProductsCount = payload
    },
    resetSteps (state) {
      Object.keys(state.steps).forEach(el => { state.steps[el] = false })
    },
    setProductValidation (state, payload: boolean) {
      state.stepsValidation.product = payload
    },
    setShippingCode (state, payload) {
      for (const index in state.products) {
        Vue.set(state.products[index], 'marketplace_delivery', payload)
      }
    },
    resetStepValidation (state) {
      Object.keys(state.stepsValidation).forEach(key => { state.stepsValidation[key] = false })
    },
    setStepValidation (state, payload) {
      state.stepsValidation[payload] = true
    },
    addProduct (state, payload) {
      state.products.push(cloneDeep(payload))
    },
    setNewProducts (state, payload) {
      if (payload._embedded && payload._embedded.items) { state.products = payload._embedded.items }
    },
    setProducts (state, payload) {
      if (payload._embedded && payload._embedded.items) { state.products = [...state.products, ...payload._embedded.items] }
    },
    setFilteredProducts (state, payload) {
      state.products = payload
    },
    resetProducts (state) {
      state.products = []
    },
    setIsLastPage (state, payload) {
      if (payload._embedded && payload._embedded.items) { state.isLastPage = !!(payload._embedded.items.length < state.productsPerPage) }
    },
    setCurrentPage (state, payload) {
      state.currentPage = payload
    },
    setAllProductsCount (state, payload) {
      if (payload.items_count) state.allProductsCount = payload.items_count
      else state.allProductsCount = 0
    }
  },
  actions: {
    afterProductSection ({ commit }) {
      commit('setStepValidation', 'product')
    },
    afterShippingSection ({ commit }) {
      commit('setStepValidation', 'shipping')
    },
    afterConfirmSection ({ commit }) {
      commit('resetStepValidation')
      commit('resetProducts')
    },
    goToStep ({ state, commit, dispatch, rootGetters }, payload) {
      let stepsKeys = Object.keys(state.steps)
      if (!stepsKeys.some(el => el === payload)) return
      if (!rootGetters['user/isLoggedIn']) return
      commit('resetSteps')
      commit('setStep', payload)
      if (!isServer) {
        window.scrollTo(0, 0)
        window.location.href = window.location.origin + window.location.pathname + window.location.search + '#' + payload
      }
    },
    setFirstStep ({ state, commit, rootGetters }) {
      commit('resetSteps')

      if (rootGetters['user/isLoggedIn']) {
        state.steps['product'] = true
        if (!isServer) window.location.href = window.location.origin + window.location.pathname + window.location.search + '#' + 'product'
      } else {
        state.steps['register'] = true
        if (!isServer) window.location.href = window.location.origin + window.location.pathname + window.location.search + '#' + 'register'
      }
    },
    removeProduct ({ state, commit, dispatch }, payload) {
      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t(`Czy na pewno chcesz usunąć produkt ${payload.name}?`),
        action1: {
          label: i18n.t('Ok'),
          action: () => {
            const newProducts = state.products.filter(product => product.identifier !== payload.identifier)
            commit('setFilteredProducts', newProducts)
            if (newProducts.length === 0) {
              commit('resetStepValidation')
              dispatch('setFirstStep')
            }
          }
        },
        action2: {
          label: i18n.t('Cancel'),
          action: 'close'
        },
        hasNoTimeout: true
      })
    },
    async getProducts ({ state, commit }, { activeFilters, currentPage }) {
      try {
        const result = await AkeneoService.getProducts(state.productsPerPage, activeFilters, currentPage)
        commit('setIsLastPage', result)
        commit('setAllProductsCount', result)
        commit('setCurrentPage', currentPage)

        currentPage === 1
          ? commit('setNewProducts', result)
          : commit('setProducts', result)
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      }
    },
    async getAllCategories ({ commit }) {
      const limit = 100
      try {
        const result = await AkeneoService.getCategories(limit)
        commit('setCategories', result)
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      } finally {
        EventBus.$emit('marketplace-categories-after-load')
      }
    },
    async getFamilyForCategory ({ commit }, payload) {
      try {
        const familyCode = getCurrentFamily(payload)
        const result = await AkeneoService.getFamily(familyCode)
        commit('setFullAttributeCodesList', result['attributes'])
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      }
    },
    async getAttributes ({ state, commit }) {
      try {
        const result = await AkeneoService.getAttributes(state.fullAttributeCodesList)
        commit('setFullAttributesMap', result)
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      }
    },
    async getDropdownAttrsOptions ({ state, commit }, payload) {
      const limit = 100
      try {
        let promisesList = payload.map(code => AkeneoService.getAttributeOptions(code, limit))
        const resultAll = await Promise.all(promisesList)
        for (let i = 0; i < resultAll.length; i++) {
          if (resultAll[i]) commit('setDropdownAttrsOptions', { code: payload[i], result: resultAll[i] })
        }
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      } finally {
        EventBus.$emit('marketplace-attrs-after-load')
      }
    },
    async createSeveralProducts ({ state, commit }, payload) {
      try {
        EventBus.$emit('notification-progress-start')
        const preparedProducts = prepareProducts(state.products, payload, state.fullAttributesMap)
        let promisesList = preparedProducts.map(product => AkeneoService.createSeveralProducts(product))
        const resultAll = await Promise.all(promisesList)
        let createdProductsCount = 0
        let successProducts = []
        resultAll.forEach((result, index) => {
          if (result['code'] === 201) {
            createdProductsCount++
            successProducts.push(preparedProducts[index])
          }
        })
        commit('setAddedProductsCount', createdProductsCount)
        for (const successProduct of successProducts) {
          const images = state.products.find(product => product.identifier === successProduct.identifier).uploadedImages
          for (const imageKey in images) {
            const akeneoProduct = {
              'identifier': successProduct.identifier,
              'attribute': imageKey,
              'scope': null,
              'locale': null
            }
            await AkeneoService.setAssetForProduct(akeneoProduct, images[imageKey])
          }
        }
        rootStore.dispatch('notification/spawnNotification', {
          type: createdProductsCount ? 'success' : 'error',
          message: createdProductsCount
            ? i18n.t(`${createdProductsCount} product(s) was created`)
            : i18n.t('The product(s) was not created'),
          action1: { label: i18n.t('OK') }
        })
      } catch (e) {
        return Logger.error(e, 'marketplace')()
      } finally {
        EventBus.$emit('notification-progress-stop')
      }
    }
  }
}
