import { CategoryResolver } from 'theme/helpers/category_resolver/CategoryResolver'
import { CategoryResolverConfig } from 'theme/helpers/category_resolver/CategoryResolverConfig'

export function categoryResolver (category, store, rootId) {
  const menuCategories = store.getters['category-next/getMenuCategories']
  const config = new CategoryResolverConfig({ cropCategoriesBefore: 1 })
  let resolver = CategoryResolver.get(config)
  resolver.setCategories(menuCategories)
  let categoriesIdsFromResolver = []
  let deepestCategory = []
  if (!category) {
    return { deepestCategory, categoriesIdsFromResolver }
  }
  categoriesIdsFromResolver = resolver.getDeepestCategories(category, false)
  categoriesIdsFromResolver = categoriesIdsFromResolver.filter(pathId => pathId && (pathId.includes('/' + rootId + '/') || pathId.endsWith('/' + rootId)))
  deepestCategory = categoriesIdsFromResolver.map((x) => resolver.getNamedPath(x))
  return { deepestCategory, categoriesIdsFromResolver }
}
