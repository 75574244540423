import { Wordpress } from '../types/Wordpress';
import { GetterTree } from 'vuex';

export const getters: GetterTree<Wordpress, any> = {
  posts (state) {
    return state.posts
  },
  press (state) {
    return state.press
  },
  allPages (state) {
    return state.allPages
  },
  currentPage (state) {
    return state.currentPage
  },
  currentEdges (state) {
    return state.currentEdges
  },
  categories (state) {
    return state.categories
  },
  currentPost (state) {
    return state.currentPost
  },
  currentCategory (state) {
    return state.currentCategory
  },
  categoriesPreview (state) {
    return state.categoriesPreview
  }
}
