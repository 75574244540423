import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import StockState from '../../types/StockState';

const getters: GetterTree<StockState, RootState> = {
  getIsStockInfoLoading: state => state.isStockInfoLoading,
  getMaxQuantity: state => state.maxQuantity
}

export default getters
